import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

/**
 * @file Title Component
 * @description A title component that provides various styling options for headings.
 *
 * @remarks
 * This component uses `class-variance-authority` (cva) for managing styles and variants.
 * It supports different sizes, weights, text wrapping, leading, tracking, and italic styles.
 *
 * @see TitleProps for a list of available props
 *
 * @returns {React.ReactElement} A React.ReactElement representing a title component.
 *
 * @example
 * Basic usage:
 * ```jsx
 * <Title>This is a title</Title>
 * ```
 *
 * With different size and weight:
 * ```jsx
 * <Title size="headline-medium" weight="bold">
 *   This is a title
 * </Title>
 * ```
 *
 * With different HTML element:
 * ```jsx
 * <Title el="h1" size="display-large">
 *   This is a display title
 * </Title>
 * ```
 */
const headingVariants = cva('', {
  variants: {
    size: {
      'display-large': 'text-display-large',
      'display-medium': 'text-display-medium',
      'display-small': 'text-display-small',
      'headline-large': 'text-headline-large',
      'headline-medium': 'text-headline-medium',
      'headline-small': 'text-headline-small',
      'title-large': 'text-title-large',
      'title-medium': 'text-title-medium',
      'title-small': 'text-title-small',
    },
    weight: {
      thin: 'font-thin',
      extralight: 'font-extralight',
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold',
      black: 'font-black',
    },
    textWrap: {
      normal: '', // Default browser behavior
      balance: 'text-balance', // Balance lines
      pretty: 'text-pretty', // Better widow/orphan handling
      nowrap: 'text-nowrap', // Prevent wrapping
    },
    leading: {
      none: 'leading-none',
      tight: 'leading-tight',
      snug: 'leading-snug',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
      loose: 'leading-loose',
    },
    tracking: {
      tighter: 'tracking-tighter',
      tight: 'tracking-tight',
      normal: 'tracking-normal',
      wide: 'tracking-wide',
      wider: 'tracking-wider',
      widest: 'tracking-widest',
    },
    italic: {
      true: 'italic',
    },
  },
  defaultVariants: {
    size: 'headline-large',
    textWrap: 'normal',
  },
})

/**
 * @interface TitleProps
 * @description Defines the props for the Title component.
 * @extends React.HTMLAttributes<HTMLHeadingElement>
 * @extends Omit<VariantProps<typeof headingVariants>, 'el'>
 *
 * @property {('h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p')} [el='h2'] - The HTML element to render as.
 * @property {React.ReactNode} [children] - The content to render within the title.
 * @property {('display-large' | 'display-medium' | 'display-small' | 'headline-large' | 'headline-medium' | 'headline-small' | 'title-large' | 'title-medium' | 'title-small')} [size='headline-large'] - The size of the title.
 * @property {('thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black')} [weight] - The font weight of the title.
 * @property {('none' | 'tight' | 'snug' | 'normal' | 'relaxed' | 'loose')} [leading] - The leading (line height) of the title.
 * @property {('tighter' | 'tight' | 'normal' | 'wide' | 'wider' | 'widest')} [tracking] - The tracking (letter spacing) of the title.
 * @property {boolean} [italic] - Whether the title should be italicized.
 * @property {string} [className] - Optional CSS class name for the component.
 * @property {('normal' | 'balance' | 'pretty' | 'nowrap')} [textWrap] - The text wrapping style.
 */
interface TitleProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    Omit<VariantProps<typeof headingVariants>, 'el'> {
  el?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
  children?: React.ReactNode
  size?:
    | 'display-large'
    | 'display-medium'
    | 'display-small'
    | 'headline-large'
    | 'headline-medium'
    | 'headline-small'
    | 'title-large'
    | 'title-medium'
    | 'title-small'
  weight?:
    | 'thin'
    | 'extralight'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semibold'
    | 'bold'
    | 'extrabold'
    | 'black'
  leading?: 'none' | 'tight' | 'snug' | 'normal' | 'relaxed' | 'loose'
  tracking?: 'tighter' | 'tight' | 'normal' | 'wide' | 'wider' | 'widest'
  italic?: boolean
  className?: string
  textWrap?: 'normal' | 'balance' | 'pretty' | 'nowrap'
}

export const Title = React.forwardRef<HTMLHeadingElement, TitleProps>(
  (
    {
      el = 'h2',
      size = 'headline-large',
      weight,
      leading,
      tracking,
      italic,
      children,
      className,
      textWrap,
      ...props
    },
    ref,
  ) => {
    const Component = el
    const variants = headingVariants({
      size,
      weight,
      leading,
      tracking,
      italic,
      textWrap,
    })

    return (
      <Component ref={ref} className={`${variants} ${className || ''}`} {...props}>
        {children}
      </Component>
    )
  },
)

Title.displayName = 'Title'
