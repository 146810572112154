import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'

import { cn } from '@/utils/ui'

/**
 * @file AlertDialog Component
 * @description A modal dialog that interrupts the user with important content and expects a response.
 * This component is specifically designed for critical confirmations or alerts that need user acknowledgment.
 *
 * @remarks
 * This component uses Radix UI's AlertDialog primitive and `class-variance-authority` (cva)
 * for styling and variants.
 *
 * @returns {React.ReactElement} An alert dialog component with the specified styles and variants.
 */

/**
 * @const alertDialogVariants
 * @description Defines the style variants for the AlertDialog component using `class-variance-authority`.
 * 
 * @variants
 * - `variant`: Defines the visual style of the alert dialog.
 *   - `default`: Standard alert dialog style.
 *   - `destructive`: Alert dialog style for destructive actions.
 * - `size`: Defines the size of the alert dialog.
 *   - `sm`: Small sized alert dialog.
 *   - `default`: Standard sized alert dialog.
 *   - `lg`: Large sized alert dialog.
 * - `intensity`: Defines the visual intensity of the alert dialog.
 *   - `low`: Subtle, low-priority alert.
 *   - `medium`: Standard priority alert.
 *   - `high`: High priority, attention-grabbing alert.
 * - `animation`: Defines the animation timing of the alert dialog.
 *   - `default`: Standard animation timing.
 *   - `fast`: Fast animation for quick interactions.
 *   - `slow`: Slow animation for emphasis.
 */
const alertDialogVariants = cva(
  'fixed z-50 bg-neutral-50 p-6 shadow-lg border rounded-lg dark:bg-neutral-950',
  {
    variants: {
      variant: {
        default: 'border-neutral-200 dark:border-neutral-800',
        destructive: 'border-red-500 dark:border-red-900',
      },
      size: {
        sm: 'w-full max-w-[425px]',
        default: 'w-full max-w-[500px]',
        lg: 'w-full max-w-[640px]',
      },
      intensity: {
        low: '',
        medium: 'border-l-4',
        high: 'border-l-8',
      },
      animation: {
        default: 'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-bottom-[2%] data-[state=open]:slide-in-from-bottom-[2%] duration-200',
        fast: 'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-bottom-[2%] data-[state=open]:slide-in-from-bottom-[2%] duration-150',
        slow: 'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-bottom-[2%] data-[state=open]:slide-in-from-bottom-[2%] duration-300',
      },
    },
    compoundVariants: [
      {
        variant: 'destructive',
        intensity: 'low',
        className: 'text-red-500 dark:text-red-400',
      },
      {
        variant: 'destructive',
        intensity: 'medium',
        className: 'text-red-500 dark:text-red-400 border-l-red-500 dark:border-l-red-700',
      },
      {
        variant: 'destructive',
        intensity: 'high',
        className: 'text-red-500 dark:text-red-400 border-l-red-500 dark:border-l-red-700',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
      intensity: 'low',
      animation: 'default',
    },
  }
)

/**
 * @const overlayVariants
 * @description Defines the style variants for the AlertDialog overlay using `class-variance-authority`.
 */
const overlayVariants = cva(
  'fixed inset-0 z-50 bg-black/50 transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in',
  {
    variants: {
      animation: {
        default: '',
        fast: 'data-[state=closed]:duration-150 data-[state=open]:duration-150',
        slow: 'data-[state=closed]:duration-300 data-[state=open]:duration-300',
      },
    },
    defaultVariants: {
      animation: 'default',
    },
  }
)

/**
 * AlertDialogPortal Component
 * The portal component that renders the dialog into the body.
 */
const AlertDialogPortal = AlertDialogPrimitive.Portal

/**
 * AlertDialogOverlay Component
 * The overlay that covers the screen when the alert dialog is open.
 */
const AlertDialogOverlay = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay> & 
    VariantProps<typeof overlayVariants>
>(({ className, animation, ...props }, ref) => (
  <AlertDialogPrimitive.Overlay
    className={cn(overlayVariants({ animation }), className)}
    {...props}
    ref={ref}
  />
))
AlertDialogOverlay.displayName = 'AlertDialogOverlay'

/**
 * @interface AlertDialogContentProps
 * @description Defines the props for the AlertDialogContent component.
 * @extends React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
 * @extends VariantProps<typeof alertDialogVariants>
 */
export interface AlertDialogContentProps
  extends React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>,
    VariantProps<typeof alertDialogVariants> {
  /**
   * Props to pass to the overlay component.
   */
  overlayProps?: React.ComponentPropsWithoutRef<typeof AlertDialogOverlay> & 
    VariantProps<typeof overlayVariants>;
}

/**
 * AlertDialogContent Component
 * The main content container for the alert dialog.
 */
const AlertDialogContent = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Content>,
  AlertDialogContentProps
>(({ 
  className, 
  children,
  variant = 'default',
  size = 'default',
  intensity = 'low',
  animation = 'default',
  overlayProps,
  ...props 
}, ref) => (
  <AlertDialogPortal>
    <AlertDialogOverlay animation={animation} {...overlayProps} />
    <AlertDialogPrimitive.Content
      ref={ref}
      className={cn(
        alertDialogVariants({ variant, size, intensity, animation }),
        'fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]',
        className
      )}
      {...props}
    >
      {children}
    </AlertDialogPrimitive.Content>
  </AlertDialogPortal>
))
AlertDialogContent.displayName = 'AlertDialogContent'

/**
 * AlertDialogHeader Component
 * A header section for the alert dialog that includes consistent spacing.
 */
const AlertDialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'mb-4 flex flex-col gap-1.5 text-center',
      className
    )}
    {...props}
  />
)
AlertDialogHeader.displayName = 'AlertDialogHeader'

/**
 * AlertDialogFooter Component
 * A footer section for the alert dialog that includes consistent spacing and button layout.
 */
const AlertDialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'mt-6 flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...props}
  />
)
AlertDialogFooter.displayName = 'AlertDialogFooter'

/**
 * AlertDialogTitle Component
 * A title component for the alert dialog with appropriate styling.
 */
const AlertDialogTitle = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg font-semibold text-neutral-900 dark:text-neutral-50',
      className
    )}
    {...props}
  />
))
AlertDialogTitle.displayName = 'AlertDialogTitle'

/**
 * AlertDialogDescription Component
 * A description component for the alert dialog with appropriate styling.
 */
const AlertDialogDescription = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Description
    ref={ref}
    className={cn('text-sm text-neutral-500 dark:text-neutral-400', className)}
    {...props}
  />
))
AlertDialogDescription.displayName = 'AlertDialogDescription'

/**
 * AlertDialog Root Component
 * The main component for creating an alert dialog.
 */
const AlertDialog = AlertDialogPrimitive.Root

/**
 * AlertDialogTrigger Component
 * A trigger component for opening the alert dialog.
 */
const AlertDialogTrigger = AlertDialogPrimitive.Trigger

/**
 * AlertDialogAction Component
 * A component for the primary action button in the alert dialog.
 */
const AlertDialogAction = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Action
    ref={ref}
    className={cn(
      'inline-flex h-10 items-center justify-center rounded-md bg-neutral-900 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-neutral-800 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:bg-neutral-100 dark:text-neutral-900 dark:hover:bg-neutral-200',
      className
    )}
    {...props}
  />
))
AlertDialogAction.displayName = 'AlertDialogAction'

/**
 * AlertDialogCancel Component
 * A component for the cancel button in the alert dialog.
 */
const AlertDialogCancel = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Cancel
    ref={ref}
    className={cn(
      'inline-flex h-10 items-center justify-center rounded-md border border-neutral-200 bg-transparent px-4 py-2 text-sm font-medium text-neutral-900 transition-colors hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:text-neutral-100 dark:hover:bg-neutral-800',
      'mt-2 sm:mt-0',
      className
    )}
    {...props}
  />
))
AlertDialogCancel.displayName = 'AlertDialogCancel'

export {
  AlertDialog,
  AlertDialogPortal,
  AlertDialogOverlay,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
  alertDialogVariants,
  overlayVariants,
} 