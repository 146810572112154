import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { X } from 'lucide-react'

import { cn } from '@/utils/ui'

/**
 * @file Sheet Component
 * @description A sheet component for displaying content that slides in from the edge of the screen.
 * This is an extension of a dialog, designed for side panel navigation, settings, or additional context.
 *
 * @remarks
 * This component uses Radix UI's Dialog primitive and `class-variance-authority` (cva)
 * for styling and variants.
 *
 * @returns {React.ReactElement} A sheet component with the specified styles and variants.
 */

/**
 * @const sheetVariants
 * @description Defines the style variants for the Sheet component using `class-variance-authority`.
 * 
 * @variants
 * - `side`: Defines which side the sheet appears from.
 *   - `top`: Sheet comes from the top of the screen.
 *   - `right`: Sheet comes from the right of the screen.
 *   - `bottom`: Sheet comes from the bottom of the screen.
 *   - `left`: Sheet comes from the left of the screen.
 * - `size`: Defines the size of the sheet.
 *   - `sm`: Small sized sheet.
 *   - `default`: Standard sized sheet.
 *   - `lg`: Large sized sheet.
 *   - `xl`: Extra large sized sheet.
 *   - `full`: Full screen sized sheet.
 * - `variant`: Defines the visual style of the sheet.
 *   - `default`: Standard sheet style.
 *   - `destructive`: Sheet style for destructive actions.
 * - `overlay`: Defines the style of the overlay behind the sheet.
 *   - `default`: Standard overlay with medium opacity.
 *   - `light`: Light overlay with low opacity.
 *   - `dark`: Dark overlay with high opacity.
 *   - `blur`: Blurred overlay for a glass effect.
 * - `animation`: Defines the animation timing of the sheet.
 *   - `default`: Standard animation timing.
 *   - `fast`: Fast animation for quick interactions.
 *   - `slow`: Slow animation for emphasis.
 */
const sheetVariants = cva(
  'fixed z-50 gap-4 bg-neutral-50 dark:bg-neutral-950 p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        right: 'inset-y-0 right-0 h-full border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
        bottom: 'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left',
      },
      size: {
        sm: '',
        default: '',
        lg: '',
        xl: '',
        full: '',
      },
      variant: {
        default: 'border-neutral-200 dark:border-neutral-800',
        destructive: 'border-red-500 dark:border-red-900 text-red-50 dark:text-red-50 bg-red-500 dark:bg-red-900',
      },
      overlay: {
        default: '',
        light: '',
        dark: '',
        blur: '',
      },
      animation: {
        default: '',
        fast: 'data-[state=closed]:duration-200 data-[state=open]:duration-300',
        slow: 'data-[state=closed]:duration-500 data-[state=open]:duration-700',
      },
    },
    compoundVariants: [
      // Size variants for top/bottom
      {
        side: ['top', 'bottom'],
        size: 'sm',
        className: 'h-1/4',
      },
      {
        side: ['top', 'bottom'],
        size: 'default',
        className: 'h-1/3',
      },
      {
        side: ['top', 'bottom'],
        size: 'lg',
        className: 'h-1/2',
      },
      {
        side: ['top', 'bottom'],
        size: 'xl',
        className: 'h-2/3',
      },
      {
        side: ['top', 'bottom'],
        size: 'full',
        className: 'h-screen',
      },
      // Size variants for left/right
      {
        side: ['left', 'right'],
        size: 'sm',
        className: 'w-1/4',
      },
      {
        side: ['left', 'right'],
        size: 'default',
        className: 'w-1/3',
      },
      {
        side: ['left', 'right'],
        size: 'lg',
        className: 'w-1/2',
      },
      {
        side: ['left', 'right'],
        size: 'xl',
        className: 'w-2/3',
      },
      {
        side: ['left', 'right'],
        size: 'full',
        className: 'w-screen',
      },
    ],
    defaultVariants: {
      side: 'right',
      size: 'default',
      variant: 'default',
      overlay: 'default',
      animation: 'default',
    },
  }
)

/**
 * @const overlayVariants
 * @description Defines the style variants for the Sheet overlay using `class-variance-authority`.
 */
const overlayVariants = cva(
  'fixed inset-0 z-50 transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in',
  {
    variants: {
      overlay: {
        default: 'bg-black/40 dark:bg-black/70',
        light: 'bg-black/20 dark:bg-black/40',
        dark: 'bg-black/60 dark:bg-black/80',
        blur: 'backdrop-blur-sm bg-black/30 dark:bg-black/50',
      },
      animation: {
        default: '',
        fast: 'data-[state=closed]:duration-200 data-[state=open]:duration-300',
        slow: 'data-[state=closed]:duration-500 data-[state=open]:duration-700',
      },
    },
    defaultVariants: {
      overlay: 'default',
      animation: 'default',
    },
  }
)

const SheetPortal = DialogPrimitive.Portal

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & 
    VariantProps<typeof overlayVariants>
>(({ className, overlay, animation, ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={cn(overlayVariants({ overlay, animation }), className)}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = 'SheetOverlay'

/**
 * @interface SheetContentProps
 * @description Defines the props for the SheetContent component.
 * @extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
 * @extends VariantProps<typeof sheetVariants>
 */
export interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  /**
   * Whether to show the close button.
   * @default true
   */
  showCloseButton?: boolean;
  /**
   * Whether to scroll lock the body when the sheet is open.
   * @default true
   */
  scrollLock?: boolean;
  /**
   * Props to pass to the overlay component.
   */
  overlayProps?: React.ComponentPropsWithoutRef<typeof SheetOverlay> & 
    VariantProps<typeof overlayVariants>;
}

/**
 * SheetContent Component
 * The main content container for the sheet.
 */
const SheetContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  SheetContentProps
>(({ 
  className, 
  children, 
  side = 'right',
  size = 'default',
  variant = 'default',
  overlay = 'default',
  animation = 'default',
  showCloseButton = true,
  scrollLock = true,
  overlayProps,
  ...props 
}, ref) => (
  <SheetPortal>
    <SheetOverlay animation={animation} overlay={overlay} {...overlayProps} />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        sheetVariants({ side, size, variant, overlay, animation }),
        className
      )}
      data-scroll-lock={scrollLock}
      {...props}
    >
      {children}
      {showCloseButton && (
        <DialogPrimitive.Close
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      )}
    </DialogPrimitive.Content>
  </SheetPortal>
))
SheetContent.displayName = 'SheetContent'

/**
 * SheetHeader Component
 * A header section for the sheet that includes consistent spacing.
 */
const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col space-y-2 text-center sm:text-left',
      className
    )}
    {...props}
  />
)
SheetHeader.displayName = 'SheetHeader'

/**
 * SheetFooter Component
 * A footer section for the sheet that includes consistent spacing.
 */
const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...props}
  />
)
SheetFooter.displayName = 'SheetFooter'

/**
 * SheetTitle Component
 * A title component for the sheet with appropriate styling.
 */
const SheetTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg font-semibold text-foreground',
      className
    )}
    {...props}
  />
))
SheetTitle.displayName = 'SheetTitle'

/**
 * SheetDescription Component
 * A description component for the sheet with appropriate styling.
 */
const SheetDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-sm text-neutral-500 dark:text-neutral-400', className)}
    {...props}
  />
))
SheetDescription.displayName = 'SheetDescription'

/**
 * Sheet Root Component
 * The main component for creating a sheet.
 */
const Sheet = DialogPrimitive.Root

/**
 * SheetTrigger Component
 * A trigger component for opening the sheet.
 */
const SheetTrigger = DialogPrimitive.Trigger

/**
 * SheetClose Component
 * A component for closing the sheet.
 */
const SheetClose = DialogPrimitive.Close

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
  sheetVariants,
  overlayVariants,
} 