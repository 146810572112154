import { cn } from '@/utils/ui'
import { cva } from 'class-variance-authority'
import * as React from 'react'

/**
 * @file Card Component
 * @description A versatile card component for displaying information.
 *
 * TODO: Address card implementation inconsistencies
 * This component needs to be standardized with other card implementations in the codebase.
 * See src/components/ui/ROADMAP.md for the UI component library roadmap.
 *
 * @see CardProps for a list of available props
 *
 * @returns {React.ReactElement} A card element with the specified styles.
 */

/**
 * @interface CardProps
 * @description Defines the props for the Card component.
 * @extends React.HTMLAttributes<HTMLDivElement>
 *
 * @property {string} [className] - Additional CSS classes to apply to the card.
 */
export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const cardVariants = cva('bg-card text-card-foreground rounded-lg border shadow-sm', {
  variants: {},
  defaultVariants: {},
})

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ className, ...props }, ref) => (
  <div className={cn(cardVariants({ className }))} ref={ref} {...props} />
))
Card.displayName = 'Card'

/**
 * @interface CardHeaderProps
 * @description Defines the props for the CardHeader component.
 * @extends React.HTMLAttributes<HTMLDivElement>
 *
 * @property {string} [className] - Additional CSS classes to apply to the card header.
 */
export interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const cardHeaderVariants = cva('flex flex-col space-y-1.5 p-6', {
  variants: {},
  defaultVariants: {},
})

const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ className, ...props }, ref) => (
    <div className={cn(cardHeaderVariants({ className }))} ref={ref} {...props} />
  ),
)
CardHeader.displayName = 'CardHeader'

/**
 * @interface CardTitleProps
 * @description Defines the props for the CardTitle component.
 * @extends React.HTMLAttributes<HTMLHeadingElement>
 *
 * @property {string} [className] - Additional CSS classes to apply to the card title.
 */
export interface CardTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  className?: string
}

const cardTitleVariants = cva('text-2xl leading-none font-semibold tracking-tight', {
  variants: {},
  defaultVariants: {},
})

const CardTitle = React.forwardRef<HTMLParagraphElement, CardTitleProps>(
  ({ className, ...props }, ref) => (
    <h3 className={cn(cardTitleVariants({ className }))} ref={ref} {...props} />
  ),
)
CardTitle.displayName = 'CardTitle'

/**
 * @interface CardDescriptionProps
 * @description Defines the props for the CardDescription component.
 * @extends React.HTMLAttributes<HTMLParagraphElement>
 *
 * @property {string} [className] - Additional CSS classes to apply to the card description.
 */
export interface CardDescriptionProps extends React.HTMLAttributes<HTMLParagraphElement> {
  className?: string
}

const cardDescriptionVariants = cva('text-muted-foreground text-sm', {
  variants: {},
  defaultVariants: {},
})

const CardDescription = React.forwardRef<HTMLParagraphElement, CardDescriptionProps>(
  ({ className, ...props }, ref) => (
    <p className={cn(cardDescriptionVariants({ className }))} ref={ref} {...props} />
  ),
)
CardDescription.displayName = 'CardDescription'

/**
 * @interface CardContentProps
 * @description Defines the props for the CardContent component.
 * @extends React.HTMLAttributes<HTMLDivElement>
 *
 * @property {string} [className] - Additional CSS classes to apply to the card content.
 */
export interface CardContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const cardContentVariants = cva('p-6 pt-0', {
  variants: {},
  defaultVariants: {},
})

const CardContent = React.forwardRef<HTMLDivElement, CardContentProps>(
  ({ className, ...props }, ref) => (
    <div className={cn(cardContentVariants({ className }))} ref={ref} {...props} />
  ),
)
CardContent.displayName = 'CardContent'

/**
 * @interface CardFooterProps
 * @description Defines the props for the CardFooter component.
 * @extends React.HTMLAttributes<HTMLDivElement>
 *
 * @property {string} [className] - Additional CSS classes to apply to the card footer.
 */
export interface CardFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const cardFooterVariants = cva('flex items-center p-6 pt-0', {
  variants: {},
  defaultVariants: {},
})

const CardFooter = React.forwardRef<HTMLDivElement, CardFooterProps>(
  ({ className, ...props }, ref) => (
    <div className={cn(cardFooterVariants({ className }))} ref={ref} {...props} />
  ),
)
CardFooter.displayName = 'CardFooter'

export { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle }
