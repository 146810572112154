import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

/**
 * @file Text Component
 * @description A versatile text component that provides consistent typography with various styles and sizes.
 * Built with accessibility and responsive design in mind.
 *
 * @remarks
 * This component uses `class-variance-authority` (cva) for managing styles and variants.
 * It supports different HTML elements, sizes, weights, text wrapping, leading, tracking, fonts, and italic styles.
 *
 * @see TextProps for a list of available props
 *
 * @returns {React.ReactElement} A React.ReactElement representing a text component.
 *
 * @example
 * Basic usage:
 * ```jsx
 * <Text>This is a text</Text>
 * ```
 *
 * With different size and weight:
 * ```jsx
 * <Text size="body-large" weight="bold">
 *   This is a text
 * </Text>
 * ```
 *
 * With different HTML element:
 * ```jsx
 * <Text el="span" size="label-small">
 *   This is a label
 * </Text>
 * ```
 */
const textVariants = cva('', {
  variants: {
    level: {
      p: '',
      span: '',
      div: '',
    },
    size: {
      // Body sizes
      'body-large': 'text-body-large',
      'body-medium': 'text-body-medium',
      'body-small': 'text-body-small',

      // Label sizes
      'label-large': 'text-label-large',
      'label-medium': 'text-label-medium',
      'label-small': 'text-label-small',
    },
    weight: {
      thin: 'font-thin',
      extralight: 'font-extralight',
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold',
      black: 'font-black',
    },
    textWrap: {
      normal: '', // Default browser behavior
      balance: 'text-balance', // Balance lines
      pretty: 'text-pretty', // Better widow/orphan handling
      nowrap: 'text-nowrap', // Prevent wrapping
    },
    leading: {
      none: 'leading-none',
      tight: 'leading-tight',
      snug: 'leading-snug',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
      loose: 'leading-loose',
    },
    tracking: {
      tighter: 'tracking-tighter',
      tight: 'tracking-tight',
      normal: 'tracking-normal',
      wide: 'tracking-wide',
      wider: 'tracking-wider',
      widest: 'tracking-widest',
    },
    font: {
      sans: 'font-sans',
      mono: 'font-mono',
    },
    italic: {
      true: 'italic',
    },
  },
  defaultVariants: {
    level: 'p',
    size: 'body-medium',
    textWrap: 'normal',
  },
})

/**
 * @interface TextProps
 * @description Defines the props for the Text component.
 * @extends React.HTMLAttributes<HTMLElement>
 * @extends VariantProps<typeof textVariants>
 *
 * @property {('p' | 'span' | 'div')} [el='p'] - The HTML element to render as.
 * @property {React.ReactNode} [children] - The content to render within the text.
 * @property {('body-large' | 'body-medium' | 'body-small' | 'label-large' | 'label-medium' | 'label-small')} [size='body-medium'] - The size of the text.
 * @property {('thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black')} [weight] - The font weight of the text.
 * @property {('none' | 'tight' | 'snug' | 'normal' | 'relaxed' | 'loose')} [leading] - The leading (line height) of the text.
 * @property {('tighter' | 'tight' | 'normal' | 'wide' | 'wider' | 'widest')} [tracking] - The tracking (letter spacing) of the text.
 * @property {('sans' | 'mono')} [font] - The font family of the text.
 * @property {boolean} [italic] - Whether the text should be italicized.
 * @property {string} [className] - Optional CSS class name for the component.
 * @property {('normal' | 'balance' | 'pretty' | 'nowrap')} [textWrap] - The text wrapping style.
 */
export interface TextProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof textVariants> {
  children?: React.ReactNode
  className?: string
  el?: 'p' | 'span' | 'div'
  size?:
    | 'body-large'
    | 'body-medium'
    | 'body-small'
    | 'label-large'
    | 'label-medium'
    | 'label-small'
  weight?:
    | 'thin'
    | 'extralight'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semibold'
    | 'bold'
    | 'extrabold'
    | 'black'
  leading?: 'none' | 'tight' | 'snug' | 'normal' | 'relaxed' | 'loose'
  tracking?: 'tighter' | 'tight' | 'normal' | 'wide' | 'wider' | 'widest'
  font?: 'sans' | 'mono'
  italic?: boolean
  textWrap?: 'normal' | 'balance' | 'pretty' | 'nowrap'
}

/**
 * @function Text
 * @description A functional component that renders a text with customizable styles.
 */
export function Text({
  level,
  size,
  weight,
  textWrap,
  leading,
  tracking,
  font,
  italic,
  children,
  className,
  el = 'p',
  ...props
}: TextProps) {
  const Component = el
  const variants = textVariants({
    level,
    size,
    weight,
    textWrap,
    leading,
    tracking,
    font,
    italic,
  })

  return (
    <Component className={`${variants} ${className || ''}`} {...props}>
      {children}
    </Component>
  )
}
