import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/utils/ui'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

/**
 * @file RadioGroup Component
 * @description A customizable radio input group component for selecting a single option from a list.
 *
 * @remarks
 * This component uses `class-variance-authority` (cva) for managing radio styles and variants.
 * It supports different variants, sizes, and states.
 *
 * @returns {React.ReactElement} A radio group element with the specified styles and variants.
 */

/**
 * @const radioItemVariants
 * @description Defines the style variants for the RadioItem component using `class-variance-authority`.
 * 
 * @variants
 * - `variant`: Defines the visual style of the radio.
 *   - `default`: The standard radio style.
 *   - `outline`: A bordered radio style.
 *   - `accent`: A radio with accent color.
 * - `size`: Defines the size of the radio.
 *   - `default`: Standard size.
 *   - `sm`: Small size.
 *   - `lg`: Large size.
 */
const radioItemVariants = cva(
  'peer h-4 w-4 shrink-0 rounded-full border border-neutral-200 text-neutral-900 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'border-neutral-300 text-neutral-900',
        outline: 'border-neutral-300 text-neutral-900',
        accent: 'border-[oklch(85%_0.07_80)] text-[oklch(71.5%_0.15_80)]',
      },
      size: {
        default: 'h-4 w-4',
        sm: 'h-3 w-3',
        lg: 'h-5 w-5',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

const labelVariants = cva(
  'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      size: {
        default: 'text-sm',
        sm: 'text-xs',
        lg: 'text-base',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
)

/**
 * RadioGroup Root Component
 */
const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = 'RadioGroup'

/**
 * @interface RadioItemProps
 * @description Defines the props for the RadioItem component.
 * @extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
 * @extends VariantProps<typeof radioItemVariants>
 * 
 * @property {string} [className] - Additional CSS classes to apply to the radio.
 * @property {React.ReactNode} [label] - Label text for the radio.
 * @property {string} [labelClassName] - Additional CSS classes to apply to the label.
 * @property {React.ReactNode} [description] - Optional description text under the label.
 * @property {string} [descriptionClassName] - Additional CSS classes to apply to the description.
 */
export interface RadioItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    VariantProps<typeof radioItemVariants> {
  label?: React.ReactNode
  labelClassName?: string
  description?: React.ReactNode
  descriptionClassName?: string
}

/**
 * RadioItem Component
 * 
 * A radio button item within a RadioGroup.
 * 
 * @example
 * Basic usage:
 * ```jsx
 * <RadioGroup defaultValue="option-1">
 *   <RadioItem value="option-1" label="Option 1" />
 *   <RadioItem value="option-2" label="Option 2" />
 * </RadioGroup>
 * ```
 * 
 * With variants:
 * ```jsx
 * <RadioGroup defaultValue="option-1">
 *   <RadioItem value="option-1" variant="accent" size="lg" label="Option 1" />
 *   <RadioItem value="option-2" variant="accent" size="lg" label="Option 2" />
 * </RadioGroup>
 * ```
 * 
 * With description:
 * ```jsx
 * <RadioGroup defaultValue="option-1">
 *   <RadioItem 
 *     value="option-1" 
 *     label="Option 1" 
 *     description="This is a description for option 1"
 *   />
 * </RadioGroup>
 * ```
 */
const RadioItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioItemProps
>(({ 
  className, 
  variant, 
  size, 
  label, 
  labelClassName, 
  description, 
  descriptionClassName,
  id,
  ...props 
}, ref) => {
  // Generate ID at the top level of the component function
  const generatedId = React.useId()
  const radioId = id || generatedId
  
  return (
    <div className="flex items-start gap-2">
      <RadioGroupPrimitive.Item
        ref={ref}
        id={radioId}
        className={cn(radioItemVariants({ variant, size }), className)}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
          <div className={cn(
            "h-2 w-2 rounded-full bg-current", 
            size === "sm" ? "h-1.5 w-1.5" : "",
            size === "lg" ? "h-2.5 w-2.5" : "",
          )} />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      {(label || description) && (
        <div className="grid gap-1.5 leading-none">
          {label && (
            <label
              htmlFor={radioId}
              className={cn(
                labelVariants({ size }),
                labelClassName
              )}
            >
              {label}
            </label>
          )}
          {description && (
            <p className={cn(
              "text-sm text-neutral-500",
              size === "sm" ? "text-xs" : "",
              size === "lg" ? "text-sm" : "",
              descriptionClassName
            )}>
              {description}
            </p>
          )}
        </div>
      )}
    </div>
  )
})
RadioItem.displayName = 'RadioItem'

export { RadioGroup, RadioItem, radioItemVariants } 