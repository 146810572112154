import { cn } from '@/utils/ui'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'

/**
 * @file Tabs Component
 * @description A reusable tabs component built with Radix UI and Tailwind CSS.
 *
 * @remarks
 * This component provides a way to organize content into multiple sections that can be displayed one at a time.
 * It uses Radix UI's Tabs primitive for accessibility and keyboard navigation.
 *
 * @see TabsProps, TabsListProps, TabsTriggerProps, TabsContentProps for a list of available props
 *
 * @see {@link https://www.radix-ui.com/docs/primitives/components/tabs Radix UI Tabs}
 * @see {@link https://tailwindcss.com/ Tailwind CSS}
 *
 * @returns {React.ReactElement} A tabs component with the specified styles and variants.
 */

const TabsServer = TabsPrimitive.Root

/**
 * @interface TabsListProps
 * @description Defines the props for the TabsList component.
 * @extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
 *
 * @property {string} [className] - Additional CSS classes to apply to the tabs list.
 */
export interface TabsListProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> {
  className?: string
}

/**
 * @const TabsList
 * @description The container for the tab triggers.
 */
const TabsListServer = React.forwardRef<React.ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
  ({ className, ...props }, ref) => (
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        'bg-muted text-muted-foreground inline-flex h-10 items-center justify-center rounded-md p-1',
        className,
      )}
      {...props}
    />
  ),
)
TabsListServer.displayName = 'TabsList'

/**
 * @interface TabsTriggerProps
 * @description Defines the props for the TabsTrigger component.
 * @extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
 *
 * @property {string} [className] - Additional CSS classes to apply to the tabs trigger.
 */
export interface TabsTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> {
  className?: string
}

/**
 * @const TabsTrigger
 * @description The button that activates its associated content.
 */
const TabsTriggerServer = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabsTriggerProps
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'ring-offset-background focus-visible:ring-ring data-[state=active]:bg-background data-[state=active]:text-foreground inline-flex items-center justify-center rounded-sm px-3 py-1.5 text-sm font-medium whitespace-nowrap transition-all focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=active]:shadow-sm',
      className,
    )}
    {...props}
  />
))
TabsTriggerServer.displayName = 'TabsTrigger'

/**
 * @interface TabsContentProps
 * @description Defines the props for the TabsContent component.
 * @extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
 *
 * @property {string} [className] - Additional CSS classes to apply to the tabs content.
 */
export interface TabsContentProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> {
  className?: string
}

/**
 * @const TabsContent
 * @description The content that's associated with a tab trigger.
 */
const TabsContentServer = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  TabsContentProps
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-background focus-visible:ring-ring mt-2 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-none',
      className,
    )}
    {...props}
  />
))
TabsContentServer.displayName = 'TabsContent'

export {
  TabsServer as Tabs,
  TabsListServer as TabsList,
  TabsTriggerServer as TabsTrigger,
  TabsContentServer as TabsContent,
}
