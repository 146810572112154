import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { cn } from '@/utils/ui'

/**
 * @file Separator Component
 * @description A separator visually or semantically separates content.
 *
 * @remarks
 * This component uses `class-variance-authority` (cva) for managing separator styles and variants.
 * It's built on the Radix UI Separator primitive.
 *
 * @returns {React.ReactElement} A separator element with the specified styles and variants.
 */

/**
 * @const separatorVariants
 * @description Defines the style variants for the Separator component using `class-variance-authority`.
 * 
 * @variants
 * - `orientation`: The orientation of the separator.
 *   - `horizontal`: A horizontal separator.
 *   - `vertical`: A vertical separator.
 * - `thickness`: The thickness of the separator.
 *   - `thin`: A thin separator (1px).
 *   - `default`: A standard separator (2px).
 *   - `thick`: A thick separator (4px).
 * - `variant`: The visual style of the separator.
 *   - `default`: The standard separator style.
 *   - `muted`: A more subtle separator.
 *   - `accent`: A separator with accent color.
 * - `separatorStyle`: Visual style for the separator.
 *   - `line`: Standard line separator.
 *   - `dashed`: A dashed separator.
 *   - `dotted`: A dotted separator.
 *   - `gradient`: A gradient separator.
 *   - `glow`: A separator with a subtle glow.
 *   - `dots`: A series of individual dots (centered dot for vertical separators).
 *   - `doubleDots`: Two dots with more spacing.
 *   - `tripleDots`: Three dots with equal spacing.
 */
const separatorVariants = cva(
  'shrink-0',
  {
    variants: {
      orientation: {
        horizontal: 'w-full',
        vertical: 'h-full',
      },
      thickness: {
        thin: '',
        default: '',
        thick: '',
      },
      variant: {
        default: 'bg-neutral-200 dark:bg-neutral-800',
        muted: 'bg-neutral-100 dark:bg-neutral-900',
        accent: 'bg-[oklch(85%_0.07_80)]',
      },
      separatorStyle: {
        line: '',
        dashed: 'border-0 bg-transparent [background-image:linear-gradient(to_right,currentColor_50%,transparent_50%)] [background-size:8px_1px] [background-repeat:repeat]',
        dotted: 'border-0 bg-transparent [background-image:radial-gradient(circle,currentColor_30%,transparent_30%)] [background-size:5px_5px] [background-repeat:round]',
        gradient: 'bg-gradient-to-r from-transparent via-neutral-400 dark:via-neutral-600 to-transparent',
        glow: 'bg-transparent shadow-[0_0_5px_0px_rgba(0,0,0,0.1)]',
        dots: 'relative after:absolute after:rounded-full after:bg-current',
        doubleDots: 'relative before:absolute after:absolute before:rounded-full after:rounded-full before:bg-current after:bg-current',
        tripleDots: 'relative before:absolute after:absolute [&_span]:absolute before:rounded-full after:rounded-full [&_span]:rounded-full before:bg-current after:bg-current [&_span]:bg-current',
      },
    },
    compoundVariants: [
      // Thickness variants for horizontal orientation
      {
        orientation: 'horizontal',
        thickness: 'thin',
        className: 'h-px',
      },
      {
        orientation: 'horizontal',
        thickness: 'default',
        className: 'h-[2px]',
      },
      {
        orientation: 'horizontal',
        thickness: 'thick',
        className: 'h-1',
      },
      // Thickness variants for vertical orientation
      {
        orientation: 'vertical',
        thickness: 'thin',
        className: 'w-px',
      },
      {
        orientation: 'vertical',
        thickness: 'default',
        className: 'w-[2px]',
      },
      {
        orientation: 'vertical',
        thickness: 'thick',
        className: 'w-1',
      },
      // Dot variant for horizontal orientation
      {
        orientation: 'horizontal',
        separatorStyle: 'dots',
        className: 'h-4 bg-transparent after:left-1/2 after:-translate-x-1/2 after:top-1/2 after:-translate-y-1/2 after:h-1.5 after:w-1.5',
      },
      // Dot variant for vertical orientation
      {
        orientation: 'vertical',
        separatorStyle: 'dots',
        className: 'w-4 bg-transparent after:left-1/2 after:-translate-x-1/2 after:top-1/2 after:-translate-y-1/2 after:h-1.5 after:w-1.5',
      },
      // Double dots for horizontal
      {
        orientation: 'horizontal',
        separatorStyle: 'doubleDots',
        className: 'h-4 bg-transparent before:left-1/3 before:-translate-x-1/2 before:top-1/2 before:-translate-y-1/2 before:h-1.5 before:w-1.5 after:left-2/3 after:-translate-x-1/2 after:top-1/2 after:-translate-y-1/2 after:h-1.5 after:w-1.5',
      },
      // Double dots for vertical
      {
        orientation: 'vertical',
        separatorStyle: 'doubleDots',
        className: 'w-4 bg-transparent before:left-1/2 before:-translate-x-1/2 before:top-1/3 before:-translate-y-1/2 before:h-1.5 before:w-1.5 after:left-1/2 after:-translate-x-1/2 after:top-2/3 after:-translate-y-1/2 after:h-1.5 after:w-1.5',
      },
      // Triple dots for horizontal
      {
        orientation: 'horizontal',
        separatorStyle: 'tripleDots',
        className: 'h-4 bg-transparent before:left-1/4 before:-translate-x-1/2 before:top-1/2 before:-translate-y-1/2 before:h-1.5 before:w-1.5 after:left-3/4 after:-translate-x-1/2 after:top-1/2 after:-translate-y-1/2 after:h-1.5 after:w-1.5 [&_span]:left-1/2 [&_span]:-translate-x-1/2 [&_span]:top-1/2 [&_span]:-translate-y-1/2 [&_span]:h-1.5 [&_span]:w-1.5',
      },
      // Triple dots for vertical
      {
        orientation: 'vertical',
        separatorStyle: 'tripleDots',
        className: 'w-4 bg-transparent before:left-1/2 before:-translate-x-1/2 before:top-1/4 before:-translate-y-1/2 before:h-1.5 before:w-1.5 after:left-1/2 after:-translate-x-1/2 after:top-3/4 after:-translate-y-1/2 after:h-1.5 after:w-1.5 [&_span]:left-1/2 [&_span]:-translate-x-1/2 [&_span]:top-1/2 [&_span]:-translate-y-1/2 [&_span]:h-1.5 [&_span]:w-1.5',
      },
    ],
    defaultVariants: {
      orientation: 'horizontal',
      thickness: 'default',
      variant: 'default',
      separatorStyle: 'line',
    },
  }
)

// Define the style types for better type safety
export type SeparatorStyleType = 
  | 'line'
  | 'dashed'
  | 'dotted'
  | 'gradient'
  | 'glow'
  | 'dots'
  | 'doubleDots'
  | 'tripleDots';

// Define types for base props to avoid circular references
type SeparatorBaseProps = Omit<
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>,
  'decorative' | 'orientation'
>;

/**
 * @interface SeparatorProps
 * @description Defines the props for the Separator component.
 * @extends SeparatorBaseProps
 * @extends VariantProps<typeof separatorVariants>
 * 
 * @property {string} [className] - Additional CSS classes to apply to the separator.
 */
export interface SeparatorProps
  extends SeparatorBaseProps,
    Omit<VariantProps<typeof separatorVariants>, 'orientation'> {
  /**
   * Additional CSS classes to apply to the separator
   */
  className?: string;
  /**
   * The orientation of the separator (horizontal or vertical)
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * The visual style of the separator
   */
  separatorStyle?: SeparatorStyleType;
  /**
   * Whether the separator is purely decorative. 
   * Decorative separators are not announced by screen readers.
   * Default is true for visual separators.
   */
  isDecorative?: boolean;
}

/**
 * Separator Component
 * 
 * A versatile separator that visually or semantically separates content.
 * 
 * @example
 * Basic usage:
 * ```jsx
 * <Separator />
 * ```
 * 
 * With line variants:
 * ```jsx
 * <Separator variant="accent" separatorStyle="gradient" thickness="thick" />
 * ```
 * 
 * With dot variants:
 * ```jsx
 * <Separator separatorStyle="dots" />
 * <Separator separatorStyle="doubleDots" />
 * <Separator separatorStyle="tripleDots" />
 * ```
 * 
 * Vertical separator:
 * ```jsx
 * <div className="h-10 flex items-center">
 *   <div>Left</div>
 *   <Separator orientation="vertical" className="mx-2" />
 *   <div>Right</div>
 * </div>
 * ```
 */
const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  SeparatorProps
>(({ 
  className, 
  orientation = 'horizontal', 
  thickness,
  variant,
  separatorStyle = 'line',
  isDecorative = true,
  ...props 
}, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    decorative={isDecorative}
    orientation={orientation}
    className={cn(
      separatorVariants({ 
        orientation, 
        thickness,
        variant,
        separatorStyle,
      }), 
      className
    )}
    {...props}
  >
    {separatorStyle === 'tripleDots' && <span />}
  </SeparatorPrimitive.Root>
))
Separator.displayName = 'Separator'

export { Separator, separatorVariants } 