import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/utils/ui'
import { Slot } from '@radix-ui/react-slot'

/**
 * @file Badge Component
 * @description A versatile badge component for displaying statuses, counts, and labels.
 *
 * @remarks
 * This component uses `class-variance-authority` (cva) for managing badge styles and variants.
 * It supports different variants, sizes, and can be rendered as a child element using the `asChild` prop.
 *
 * @returns {React.ReactElement} A badge element with the specified styles and variants.
 */

/**
 * @const badgeVariants
 * @description Defines the style variants for the Badge component using `class-variance-authority`.
 *
 * @variants
 * - `variant`: Defines the visual style of the badge.
 *   - `default`: The standard badge style.
 *   - `secondary`: A subdued badge style.
 *   - `outline`: A bordered badge style.
 *   - `accent`: A badge with accent color.
 *   - `success`: A badge indicating success.
 *   - `warning`: A badge indicating warning.
 *   - `destructive`: A badge indicating danger or error.
 * - `size`: Defines the size of the badge.
 *   - `default`: Standard size.
 *   - `sm`: Small size.
 *   - `lg`: Large size.
 * - `shape`: Defines the shape of the badge.
 *   - `default`: Standard rounded shape.
 *   - `square`: Less rounded corners.
 *   - `pill`: Fully rounded pill shape.
 */
const badgeVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-neutral-900 text-white hover:bg-neutral-800',
        secondary: 'bg-neutral-100 text-neutral-900 hover:bg-neutral-200',
        outline: 'border border-neutral-200 text-neutral-900 hover:bg-neutral-100',
        accent: 'bg-[oklch(71.5%_0.15_80)] text-black hover:bg-[oklch(61.5%_0.15_80)]',
        success: 'bg-green-500 text-white hover:bg-green-600',
        warning: 'bg-amber-500 text-white hover:bg-amber-600',
        destructive: 'bg-red-500 text-white hover:bg-red-600',
      },
      size: {
        default: 'px-3 py-1 text-xs',
        sm: 'px-2 py-0.5 text-xs',
        lg: 'px-4 py-1.5 text-sm',
      },
      shape: {
        default: 'rounded-md',
        square: 'rounded-sm',
        pill: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      shape: 'default',
    },
  }
)

/**
 * @interface BadgeProps
 * @description Defines the props for the Badge component.
 * @extends React.HTMLAttributes<HTMLDivElement>
 * @extends VariantProps<typeof badgeVariants>
 * 
 * @property {boolean} [asChild=false] - Whether to render the badge as a child element.
 * @property {string} [className] - Additional CSS classes to apply to the badge.
 */
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  asChild?: boolean
}

/**
 * Badge Component
 * 
 * A versatile badge component for displaying statuses, counts, and labels.
 * 
 * @example
 * Basic usage:
 * ```jsx
 * <Badge>New</Badge>
 * ```
 * 
 * With variants:
 * ```jsx
 * <Badge variant="accent" shape="pill">Featured</Badge>
 * ```
 */
const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (props, ref) => {
    const { 
      className,
      variant,
      size,
      shape,
      children,
      asChild = false,
      ...otherProps
    } = props
    
    const Comp = asChild ? Slot : 'div'
    
    return (
      <Comp
        className={cn(badgeVariants({ variant, size, shape, className }))}
        ref={ref}
        {...otherProps}
      >
        {children}
      </Comp>
    )
  }
)

Badge.displayName = 'Badge'

export { Badge, badgeVariants } 