import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from './index.client'

export {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
}

// Example usage pattern for the Dialog
export function DialogDemo() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="rounded-md bg-black px-4 py-2 text-white">Open Dialog</button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Example Dialog</DialogTitle>
          <DialogDescription>
            This demonstrates how to use the Dialog component to create modals, lightboxes, and
            more.
          </DialogDescription>
        </DialogHeader>
        <div className="py-4">
          <p>
            This is the main content area where you can place any content such as forms, media, or
            text.
          </p>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <button className="rounded-md bg-black px-4 py-2 text-white">Close</button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
