import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/utils/ui'

/**
 * @file Tooltip Component
 * @description A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.
 *
 * @remarks
 * This component uses `class-variance-authority` (cva) for managing tooltip styles and variants.
 * It's built on the Radix UI Tooltip primitive.
 *
 * @returns {React.ReactElement} A tooltip element with the specified styles and variants.
 */

const tooltipVariants = cva(
  'z-50 overflow-hidden rounded-md border border-neutral-200 bg-white px-3 py-1.5 text-sm text-neutral-950 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-50',
  {
    variants: {
      variant: {
        default: '',
        accent: 'border-[oklch(85%_0.07_80)] bg-[oklch(85%_0.07_80)] text-black',
        destructive: 'border-red-500 bg-red-500 text-white',
      },
      size: {
        default: '',
        sm: 'px-2 py-1 text-xs',
        lg: 'px-4 py-2 text-base',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface TooltipProps extends TooltipPrimitive.TooltipProps {
  /**
   * The delay in milliseconds before the tooltip opens.
   */
  delayDuration?: number
  /**
   * Whether to skip the delay when the tooltip is triggered by keyboard navigation.
   */
  skipDelayDuration?: boolean
  /**
   * Whether to disable the tooltip.
   */
  disableHoverableContent?: boolean
}

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = ({ ...props }: TooltipProps) => <TooltipPrimitive.Root {...props} />

const TooltipTrigger = TooltipPrimitive.Trigger

export interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>,
    VariantProps<typeof tooltipVariants> {
  /**
   * The content of the tooltip.
   */
  children: React.ReactNode
  /**
   * Additional CSS classes to apply to the tooltip.
   */
  className?: string
  /**
   * The distance in pixels from the trigger.
   */
  sideOffset?: number
  /**
   * The preferred side of the trigger to render against.
   */
  side?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * The alignment of the tooltip relative to the trigger.
   */
  align?: 'start' | 'center' | 'end'
  /**
   * The variant of the tooltip.
   */
  variant?: 'default' | 'accent' | 'destructive'
  /**
   * The size of the tooltip.
   */
  size?: 'default' | 'sm' | 'lg'
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(({ className, variant, size, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(tooltipVariants({ variant, size }), className)}
    {...props}
  />
))
TooltipContent.displayName = 'TooltipContent'

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
