import { cn } from '@/utils/ui'
import * as React from 'react'
import { cva } from 'class-variance-authority'

/**
 * @file Textarea Component
 * @description A form textarea component that provides a consistent interface for multi-line text input.
 *
 * @see TextareaProps for a list of available props
 */

/**
 * @interface TextareaProps
 * @description Defines the props for the Textarea component.
 * @extends React.TextareaHTMLAttributes<HTMLTextAreaElement>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string
}

const textareaVariants = cva(
  'border-border bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex min-h-[80px] w-full rounded border px-3 py-2 text-sm focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {},
    defaultVariants: {},
  },
)

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return <textarea className={cn(textareaVariants({ className }))} ref={ref} {...props} />
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
